import React from "react";

/**
 * Notification component
 * @param {object} props
 * @param {string} props.notifType - Type of notification
 * @param {function} props.clear - Function to clear notification
 * @param {string} props.className - Additional class names
 * @param {number} props.fadeOutTimer - Time to fade out in seconds
 */
export const Notification = ({children, notifType="info", clear, className, fadeOutTimer = 10, fading = false}) => {
    
    if (!fading && parseFloat(fadeOutTimer) > 0) {
        setTimeout(() => {
            clear();
        }, fadeOutTimer * 1e3);
    }

    return (
        <div className={`s160-notif notification ${className} ${fading ? "fading": ""}`}>
            <button className="delete" onClick={clear} />
            <div className="notif-content">
                {children}
            </ div>
        </div>
    );
}
