import React from "react";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { USER_ROLES_LOOKUP_TABLE } from "../../util/constants";
import { BulmaButtonProper } from "../generic/Buttons/BulmaButtonProper";
import ModalWrapper from "./ModalFormWrapper";
import { ErrorNotification } from "../generic/Notifications/ErrorNotification";
import { Notification } from "../generic/Notifications/Notification";
import { SuccessNotification } from "../generic/Notifications/SuccessNotification";

const VALIDATION_SCHEMA = Yup.object().shape({
  userId: Yup.string().required("UserId is required"),
  password: Yup.string()
    .min(5, "Password must be at least 5 characters.")
    .required("Password is required"),
  email: Yup.string().required("Email is required"),
  phone: Yup.string()
    // Review Regex here
    .matches(/^\d{10}$/, "Phone number must be 10 digits")
    .required("Phone is required"),
  userRole: Yup.number()
    .required("User Role is required")
    .integer()
    .oneOf([0, 1, 2, 3, 4], "Must be a valid User Role value"),
  organizationId: Yup.number()
    .required("Organization ID is required")
    .positive()
    .integer(),
});

function CreateUserV2Form({
  // disabled = true,
  loading,
  error,
  organizationList,
  addUser,
  permissionlevel,
  disabled = false,
  errMsg="",
  data: previousResponse
}) {

  // Org Admins (permissionlevel 1) can only create equal/lesser roles
  const role_options = USER_ROLES_LOOKUP_TABLE.map((role, level) => [role, level]);
  const filtered_options = permissionlevel === 1 ? role_options.filter( (role) => role[0] !== "SYSTEM_ADMIN") : role_options;

  const initValues = {
    userId: "",
    password: "",
    email: "",
    phone: "",
    userRole: 1,
    organizationId: organizationList[0].organizationid,  // Necessary if there is only 1 org (eg Org_Admins)
  };

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const { email, phone, password, userRole, organizationId, userId } = values;

    // The validation should take care of protecting us from these parses
    return addUser(email, phone, password, parseInt(userRole), parseInt(organizationId), userId);
  };

  return (
    <Formik
      initialValues={initValues}
      enableReinitialize={true}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        // values,
        // handleBlur,
        // handleChange,
        // handleReset,
        handleSubmit,
        errors,
      }) => {

        // console.group("Formik render method");
        // console.log("values", values);
        // console.log("errors", errors);
        // console.log("other", isSubmitting, handleBlur, handleChange, handleSubmit)
        // console.groupEnd();

        return (
          <Form className="form">
            <ModalWrapper
              modalName="Create User"
              header="Create User"
              body={
                <>
                  <div className="field">
                    <label htmlFor="userId" className="label">
                      Username
                    </label>
                    <Field
                      type="text"
                      name="userId"
                      className="input"
                      disabled={disabled}
                    />
                    <ErrorMessage
                      name="userId"
                      component="div"
                      className="help is-danger"
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="password" className="label">
                      Password
                    </label>
                    <Field
                      type="text"
                      name="password"
                      className="input"
                      disabled={disabled}
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="help is-danger"
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="email" className="label">
                      Email
                    </label>
                    <Field
                      type="text"
                      name="email"
                      className="input"
                      disabled={disabled}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="help is-danger"
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="phone" className="label">
                      Phone
                    </label>
                    <Field
                      type="text"
                      name="phone"
                      className="input"
                      disabled={disabled}
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="help is-danger"
                    />
                  </div>
                  <div className="field">
                    <div className="control">
                      <label htmlFor="userRole" className="label">
                        User Role
                      </label>
                      <div className="select">
                        <Field as="select" name="userRole" disabled={disabled}>
                          {filtered_options.map(([role, value]) => (
                            <option key={`role-opt-${value}`} value={value}>
                              {role}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <ErrorMessage
                        name="userRole"
                        component="div"
                        className="help is-danger"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <label htmlFor="organizationId" className="label">
                        Organization
                      </label>
                      <div className="select">
                        <Field
                          as="select"
                          name="organizationId"
                          disabled={disabled}
                        >
                          {organizationList.map((item, i) => (
                            <option
                              key={`org-opt-${i}`}
                              value={item.organizationid}
                            >
                              {item.organizationname}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <ErrorMessage
                        name="organizationId"
                        component="div"
                        className="help is-danger"
                      />
                    </div>
                  </div>
                  <ErrorNotification condition={errMsg!==""} >{errMsg}</ErrorNotification>
                  <SuccessNotification condition={(previousResponse &&previousResponse.success)} >User created</SuccessNotification>
                </>
              }
              footer={
                <BulmaButtonProper
                  disabled={disabled || isSubmitting}
                  // Might want to add the endpoint loading here as well
                  loading={isSubmitting || loading}
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </BulmaButtonProper>
              }
            />
          </Form>
        );
      }}
    </Formik>
  );
}

function mapStateToProps(state) {
  const { loading, error, errMsg, data } = state.userData.addUserAuthy;
  return {
    loading, error, errMsg, data
  };
}


export default connect(mapStateToProps)(CreateUserV2Form);
