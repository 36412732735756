import React, { useEffect } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  deleteCampaignDataDefaults,
  getCampaignDataDefaults,
  updateCampaignDataDefaultsPost,
  clearOrgDefaultsData,
} from "../../actions/organizations";

function WithDefaults({
  organizationId,
  organization,
  getDefaults,
  children,
  campaignDefaults,
  clearDefaultsData,
  ...props
}) {
  const { data, loading, error, errorCode } = campaignDefaults;

  // Component will unmount equivalent...
  useEffect(
    () => () => {
      // Remove data from redux so that it doesn't persist in
      // for other orgs
      clearDefaultsData();
    },
    []
  );

  if (data == null && !loading && !error) {
    getDefaults(organizationId);
  }

  return (
    <div>
      <p>
        Update default costs for this organization. They will be applied to all
        new campaigns created under this organization.
      </p>
      <br />
      {error && errorCode === 404 && <p className="info">No defaults saved.</p>}
      {React.cloneElement(children, {
        defaultData: data,
        loading,
        error,
        organizationId,
        getDefaults,
        ...props,
      })}
    </div>
  );
}

const mapStateToProps = (state) => ({
  campaignDefaults: state.organizations.campaignDefaults,
  updateCampaignDefaults: state.organizations.updateCampaignDefaults,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDefaults: (id) => getCampaignDataDefaults(id),
      updateDefaults: (id, costPerAgentHour, costPerSMS, currency) =>
        updateCampaignDataDefaultsPost(
          id,
          costPerAgentHour,
          costPerSMS,
          currency
        ),
      deleteDefaults: (id) => deleteCampaignDataDefaults(id),
      clearDefaultsData: () => clearOrgDefaultsData(),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults);
