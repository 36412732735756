import React from "react";
import { Switch, Route, NavLink, Redirect } from "react-router-dom";

import Analytics from "../components/AnalyticsTab/Analytics";
import DeleteCampaignModal from "./modals/DeleteCampaignModal";
import ChargesContainer from "./ChargesScreen/ChargesContainer";
import { OrganizationMyAccount } from "./OrganizationMyAccount";
import Defaults from "./OrganizationDefaults/Defaults.component";
import WithDefaults from "./OrganizationDefaults/Defaults.container";

const MENU_ITEMS = ["MY ACCOUNT", "CAMPAIGNS", "ANALYTICS", "CHARGES"];

export const Organization = ({
  campaigns,
  match,
  location,
  history,
  downloadCampaignResults,
  downloadIsLoading,
  vitalsData,
  selectedOrganizationId,
  auth,
  headers = MENU_ITEMS,
  ...props
}) => {
  const basePath = "/dashboard/organizations/:id";
  const currentOrg = vitalsData.organizationarray.find(
    (o) => o.organizationid === selectedOrganizationId
  );

  const showDefaultsPage = headers.includes("DEFAULTS");

  return (
    <div className="">
      <div className="tabs">
        <ul>
          {headers.map((value, index) => {
            return (
              <li
                className={
                  location.pathname.endsWith(
                    value.toLocaleLowerCase().replace(" ", "-")
                  )
                    ? "is-active"
                    : ""
                }
                key={value + "-index"}
              >
                <NavLink
                  activeClassName="is-active"
                  to={value.toLocaleLowerCase().replace(" ", "-")}
                >
                  {value}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
      <Switch>
        <Redirect exact from={`${basePath}`} to="my-account" />
        <Route
          path={`${basePath}/analytics`}
          render={() => (
            <Analytics
              auth={auth}
              tabs={[
                { name: "campaigns", dataName: "campaign" },
                { name: "organization", dataName: "organization" },
                { name: "users", dataName: "user" },
              ]}
              initTab={0}
              campaigns={campaigns}
              organization={[currentOrg]}
              selectedOrganizationId={selectedOrganizationId}
              analyticscheckboxconfig={vitalsData.analyticscheckboxconfig}
              scope="organization"
            />
          )}
        />

        {showDefaultsPage && (
          <Route
            path={`${basePath}/defaults`}
            render={() => (
              <WithDefaults organizationId={currentOrg.organizationid}>
                <Defaults />
              </WithDefaults>
            )}
          />
        )}

        <Route
          path={`${basePath}/charges`}
          render={() => (
            <ChargesContainer
              organizationid={currentOrg.organizationid}
              campaigns={campaigns}
              itemsPerPage={20}
            />
          )}
        />
        <Route
          path={`${basePath}/campaigns`}
          render={() => (
            <React.Fragment>
              {campaigns.map((c) => (
                <React.Fragment key={c.name + "-campaign"}>
                  <div className="field">
                    <label className="label">{c.name}</label>
                  </div>
                  <div className="field is-grouped">
                    <DeleteCampaignModal
                      campaignName={c.name}
                      history={history}
                      buttonClass={
                        "button is-outlined " +
                        (c.active === "notyet" ? "is-static" : "is-danger")
                      }
                    />
                  </div>
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
        />
        <Route
          path={`${basePath}/my-account`}
          render={() => <OrganizationMyAccount {...currentOrg} />}
        />
      </Switch>
    </div>
  );
};
