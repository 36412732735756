import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Notification } from '../Notification';
import { bindActionCreators } from 'redux';
import { getT2WUrlAndDownloadFile } from '../../../redux/GlobalNotifications/actions';

class T2WDownloadReadyNotification extends Component {
    render() {
        return <Notification {...this.props} className="is-success" fadeOutTimer={0}>
            The text-to-web download is ready. <a onClick={() => this.props.getT2WUrlAndDownloadFile()}>Click here to start download.</a>
        </ Notification>;
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getT2WUrlAndDownloadFile: () => getT2WUrlAndDownloadFile()
}, dispatch);

export default connect(
    null,
    mapDispatchToProps
)(T2WDownloadReadyNotification);
