// This is the component level at which the overall state is managed.
// This component is at the same level with the login screen.
// From here overall state is passed down as props to other components.
// It gets authorization stuff via props from AuthContainer
// which itself manages whether to display the login or the dashboard.
//
// Functions which update that state should go here such as the
// following, which are then passed down via props to the components
// which need them.
//
// refreshOrganizationData:        -> DCM -> OrganizationsContainer -> AddOrganizationModal
// refreshAnalyticsCheckboxConfig: -> DCM -> AnalyticsContainer     -> Analytics
//
// Note: Perhaps the two refreshes could/should be combined?

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { authSuccess, logOutPost } from "../redux/Auth/actions";
import { vitalsDataPost } from "../actions/vitals";
import { getNavigationElements } from "../redux/Navigation/actions";
import { LoaderAndErrorPrompt } from "../components/higher-order/ComponentWithLoadingAndError";
import DCM from "../components/DCM";
import { getCookies, removeCookieList } from "../util/helper";

import { NAIDFetchData, setSelectedCampaignIndex } from "../actions/NAID";
import SocketHandlerContainer from "../components/SocketHandler/SocketHandlerContainer";
import GlobalNotifications from "../components/GlobalNotifications/GlobalNotifications";

class DCMContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      organizationarray: [],
    };
    this.fetchVitalsAndNAID = this.fetchVitalsAndNAID.bind(this);
  }

  componentDidMount() {
    const props = this.props;
    const { jwt, userid } = props.auth;
    if (jwt === "" || jwt === "crap") {
      const { userid, jwt } = getCookies();

      if (
        jwt !== "" &&
        userid !== "" &&
        jwt !== "crap" &&
        userid !== "crap" &&
        jwt !== undefined &&
        userid !== undefined
      ) {
        // The cookies are OK and we're in.
        props.authSuccess({
          jwt: jwt,
          userid: userid,
        });

        return;
      } else {
        // The cookies are bad. Logout.
        removeCookieList(["jwt", "userid"]);
        this.props.history.push("/authy-login");
        return;
      }
    }
    // The props are OK. Save cookies and proceed.
    document.cookie = "jwt=" + jwt + "; path=/;";
    document.cookie = "userid=" + userid + "; path=/;";

    this.fetchVitalsAndNAID(userid);
  }

  static getDerivedStateFromProps(props) {
    if (props.NAID.campaigns && props.NAID.campaigns.length !== 0) {
      return {
        selectedCampaignIndex: 0,
        campaigns: props.NAID.campaigns,
      };
    }

    return null;
  }

  componentDidUpdate() {
    const props = this.props;
    const { jwt, userid } = props.auth;

    this.fetchVitalsAndNAID(userid);
  }

  fetchVitalsAndNAID(userid) {
    // Get the vitals and issue a success
    if (
      !this.props.vitals.loading &&
      !this.props.vitals.error &&
      this.props.vitals.data === undefined
    ) {
      this.props.vitalsDataPost();
    }
    if (
      !this.props.NAID.loading &&
      !this.props.NAID.error &&
      !this.props.NAID.campaigns
    ) {
      this.props.NAIDFetchData(userid);
    }
    if (
      !this.props.navigation.loading &&
      !this.props.navigation.error &&
      !this.props.navigation.data
    ) {
      this.props.getNavigationElements();
    }
  }

  // render()
  // Essentially renders DCM which then renders the top bar, the left bar with the switching mechanism,
  // and the main screen which in turn renders its own contents.
  render() {
    const { userid, jwt } = this.props.auth;
    const { campaigns, selectedCampaignIndex } = this.props.NAID;

    const loading =
      this.props.NAID.loading ||
      this.props.vitals.loading ||
      !campaigns ||
      this.props.vitals.data === undefined ||
      this.props.navigation.loading ||
      !this.props.navigation.data;
    const error = this.props.NAID.error || this.props.vitals.error;

    let currentIndex = 0;
    if (campaigns && selectedCampaignIndex) {
      currentIndex = campaigns.findIndex(
        (c) => c.campaignid === selectedCampaignIndex
      );
    }

    return (
      <LoaderAndErrorPrompt {...{ loading: loading, error: error }}>
        {/* {!loading && <Redirect exact from="/dashboard" to={"/dashboard/campaigns/" + campaigns[currentIndex].name + "/"} />} */}
        {campaigns && this.props.vitals.data && this.props.navigation.data && (
          <DCM
            auth={this.props.auth}
            campaigns={campaigns}
            selectedCampaignIndex={selectedCampaignIndex}
            jwt={jwt}
            userid={userid}
            match={this.props.match}
            location={this.props.location}
            // name={!loading ? campaigns[currentIndex].name : ""}
            users={this.props.users}
            // callbacks
            vitals={this.props.vitals}
            history={this.props.history}
            dropdownElements={this.props.navigation.data.navbarAdminDropdown}
            pages={this.props.navigation.data.pages}
            tools={this.props.navigation.data.tools} // TODO: Rename as userPermissions
            orgHeaders={this.props.navigation.data.orgHeaders}
          />
        )}
        <SocketHandlerContainer vitals={this.props.vitals} userid={userid} />
        <GlobalNotifications campaigns={campaigns} />
      </LoaderAndErrorPrompt>
    );
  }
}

const mapStateToProps = (state) => ({
  NAID: state.NAID,
  users: state.userData,
  vitals: state.vitals,
  navigation: state.navigation,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      NAIDFetchData: (userid) => NAIDFetchData(userid),
      setSelectedCampaignIndex: (id) => setSelectedCampaignIndex(id),
      authSuccess: (data) => authSuccess(data),
      logOutPost: () => logOutPost(),
      vitalsDataPost,
      getNavigationElements,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DCMContainer);
