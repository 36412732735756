import React from "react";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from "formik";
import * as Yup from "yup";

import { BulmaButtonProper } from "../generic/Buttons/BulmaButtonProper";

const VALIDATION_SCHEMA = Yup.object().shape({
  costPerAgentHour: Yup.number().test(
    "isFloat",
    "number must be a valid floating point number",
    (number) => parseFloat(number)
  ),
  costPerSMS: Yup.number().test(
    "isFloat",
    "number must be a valid floating point number",
    (number) => parseFloat(number)
  ),
});

function Defaults({
  organizationId,
  defaultData,
  updateDefaults,
  loading,
  deleteDefaults,
}) {
  function clearDefaults() {
    return deleteDefaults(organizationId);
  }

  /**
   * Formik submit callback
   * @param {*} values
   * @param {*} param1
   * @returns
   */
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    if (values.costPerAgentHour === "" || values.costPerSMS === "") {
      console.error("Invalid values submitted", values);
      return;
    }

    // The submit type is a workaround to the fact that clearing the
    // values in the DB, didn't seem to clear the form. With this,
    // I can use resetForm() and clear the defaults in the backend.
    if (values.submitType == "SAVE") {
      values.submitType = "";
      console.log("values", values);
      return updateDefaults(
        organizationId,
        typeof values.costPerAgentHour === "number" ? values.costPerAgentHour.toFixed(2) : values.costPerAgentHour,
        typeof values.costPerSMS === "number" ? values.costPerSMS.toFixed(2) : values.costPerSMS,
        "dollars"
      );
    } else if (values.submitType == "DELETE") {
      resetForm();
      return clearDefaults();
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        costPerAgentHour: defaultData ? defaultData.costperagenthour : "",
        costPerSMS: defaultData ? defaultData.smssurcharge : "",
      }}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({
        isSubmitting,
        values,
        // handleBlur,
        // handleChange,
        // handleReset,
        handleSubmit,
        dirty,
        errors,
      }) => {
        // console.group("Formik render method");
        // console.log("values", values);
        // console.log("errors", errors);
        // console.log("dirty", dirty);
        // console.log("other", isSubmitting, handleSubmit);
        // console.groupEnd();

        return (
          <Form className="form">
            <div className="field is-grouped">
              <div className="control">
                <label htmlFor="costPerAgentHour" className="label">
                  Cost per Agent Hour
                </label>

                <div className="control has-icons-left">
                  <span className="icon is-left">
                    <i className="icon is-left">$</i>
                  </span>
                  <Field
                    type="number"
                    name="costPerAgentHour"
                    className="input"
                    dataType="currency"
                    step="0.01"
                    default={0.0}
                  />
                  <ErrorMessage
                    name="costPerAgentHour"
                    component="div"
                    className="help is-danger"
                  />
                </div>
              </div>
              <div className="control ">
                <label htmlFor="costPerSMS" className="label">
                  Cost per SMS
                </label>
                <div className="control has-icons-left">
                  <span className="icon is-left">
                    <i className="icon is-left">$</i>
                  </span>
                  <Field
                    type="number"
                    name="costPerSMS"
                    className="input"
                    dataType="currency"
                    step="0.01"
                    default={0.0}
                  />
                  <ErrorMessage
                    name="costPerSMS"
                    component="div"
                    className="help is-danger"
                  />
                </div>
              </div>
            </div>
            <BulmaButtonProper
              disabled={isSubmitting || !dirty}
              // Might want to add the endpoint loading here as well
              loading={isSubmitting || loading}
              type="submit"
              onClick={() => {
                values.submitType = "SAVE";
              }}
            >
              Save
            </BulmaButtonProper>

            <BulmaButtonProper
              disabled={isSubmitting}
              // Might want to add the endpoint loading here as well
              // loading={isSubmitting || loading}
              type="submit"
              customClass={"is-danger"}
              onClick={() => {
                values.submitType = "DELETE";
              }}
            >
              Clear
            </BulmaButtonProper>
          </Form>
        );
      }}
    </Formik>
  );
}

export default Defaults;