import { SERVER_URLROOT } from "../util/constants";
import { fetchRequest } from "../middleware/fetchMiddleware";
import FileSaver from "file-saver";

// TODO: Probably need to delete this
export function billingDataPost(campaignid) {
  return dispatch => {
    dispatch(
      fetchRequest("BILLING", "POST", "/gettwiliodata", {
        campaignid: campaignid
      })
    );
  };
}

/**
 * Pass either one of the two. Campaign id will take precedence.
 * @param {int} campaignid
 * @param {int} organizationid
 */
export function getChargeHistory(campaignid, organizationid) {
  return dispatch => {
    dispatch(
      fetchRequest("CHARGE_HISTORY", "POST", "/getChargeHistory", {
        campaignid: campaignid,
        organizationid: organizationid
      })
    );
  };
}


/**
 * Pass either one of the two. Campaign id will take precedence.
 * NOTE: Because this doesn't return a JSON response we can't use fetchRequest middleware!
 * @param {int} campaignid
 * @param {int} organizationid
 */
export function downloadChargeHistory(campaignid, organizationid) {
  return (dispatch, getState) => {
    const params = JSON.stringify({
      campaignid: campaignid,
      organizationid: organizationid,
      jwt: getState().auth.jwt
    });

    const myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("accept", "application/json");

    const init = {
      method: "POST",
      headers: myHeaders,
      body: params
    };

    return fetch(SERVER_URLROOT +"/downloadChargeHistory", init)
      .then(response => {
        if (!response.ok) {

          throw Error(response.statusText);
        }

        return response;
      })
      .then(response => response.text())
      .then(data => {
        const filename =
          organizationid + "_charge_history.csv";
        const blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        FileSaver.saveAs(blob, filename);
      })
      .catch(error => {

        console.log(error);
      });
  };
}



/**
 * Pass either one of the two. Campaign id will take precedence.
 * @param {int} campaignid
 * @param {int} organizationid
 */
export function applyCharge(organizationid, campaignid, referencedCharge, amount, memo) {
  return dispatch => {
    dispatch(
      fetchRequest("APPLY_CHARGE", "POST", "/applyCharge", {
        campaignid: campaignid,
        organizationid: organizationid,
        referencedCharge: referencedCharge,
        amount: amount,
        memo: memo
      })
    );
  };
}

/**
 * Pass either one of the two. Campaign id will take precedence.
 * @param {int} campaignid
 * @param {int} organizationid
 */
export function getCurrentSpend(organizationid, campaignid) {
  return dispatch => {
    dispatch(
      fetchRequest("CURRENT_SPEND", "POST", "/getCurrentSpend", {
        campaignid: campaignid,
        organizationid: organizationid
      })
    );
  };
}
