import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Notification } from '../Notification';
import { bindActionCreators } from 'redux';
import { getOptoutDownloadURL } from '../../../redux/OptoutList/actions';

class OptoutDownloadReadyNotification extends Component {
    render() {
        return <Notification {...this.props} className="is-success" fadeOutTimer={0}>
            The optout list download is ready. <a onClick={() => this.props.getOptoutDownloadURL()}>Click here to start download.</a>
        </ Notification>;
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getOptoutDownloadURL
}, dispatch);

export default connect(
    null,
    mapDispatchToProps
)(OptoutDownloadReadyNotification);
