import { fetchRequest } from "../../middleware/fetchMiddleware";
import { UPLOAD_STATUS_MAP } from "../../util/constants";

export function getUploadURL(campaignid, file, merge=false, opt_out=false, misc_update=false, compress=false, userPermissions=[]) {
  return async (dispatch) => {

    // Frontend filesize check (js file.size is always in bytes)
    if (!userPermissions.includes("ADD_CAMPAIGN_RECIPIENTS_WITHOUT_LIMITS")) {
      const maxSizeInBytes = (parseInt(process.env.REACT_APP_S160_RECIPIENT_FILESIZE_MAX_MB) * 1024 * 1024) || 0;
      if (file.size > maxSizeInBytes && maxSizeInBytes > 0) {
        const filesizeString = ((file.size / (1024 * 1024)) < 1) ?  // is filesize (converted from bytes to MB) less than 1MB? 
          `${(file.size / 1024).toFixed(2)}KB` :
          `${((file.size / (1024 * 1024)).toFixed(2))}MB`;
        const maxFileSizeString = ((maxSizeInBytes / (1024 * 1024)) < 1) ?
          `${Math.floor(maxSizeInBytes / 1024)}KB` :
          `${Math.floor(maxSizeInBytes / (1024 * 1024))}MB`;
        dispatch(setUploadStatus(UPLOAD_STATUS_MAP.FAILED, `File exceeds ${maxFileSizeString} limit. File size: ${filesizeString}`));
        return;
      }
    }

    // First generate a URL for the upload
    const data = await dispatch(
      fetchRequest("GET_UPLOAD_URL", "POST", "/getUploadURL", {
        filename: file,
        campaignid: campaignid,
        compress
      })
    );
    console.log("url", data)
    if (!data.url || data === null || data === undefined) {
      dispatch(setUploadStatus(UPLOAD_STATUS_MAP.FAILED, "Failed to create URL."));
      return;
    }

    // Second upload the file to that URL
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/csv");
    if (compress) {
      myHeaders.append("Content-Encoding", "gzip");
    }

    const init = {
      method: "PUT",
      headers: myHeaders,
      body: file,
    };

    dispatch({ type: "DIRECT_UPLOAD_IS_LOADING"});

    const response = await fetch(data.url, init);
    if (!response.ok) {
      console.error("Failed to create signed url for upload.", response.status);

      return;
    }

    await dispatch(
      fetchRequest("DIRECT_UPLOAD_FINISHED", "POST", "/directUploadFinished", {
        campaignid,
        merge,
        opt_out,
        misc_update
      })
    );

    if(misc_update){
      dispatch(setUploadStatus(UPLOAD_STATUS_MAP.COMPLETE));
    } else {
      dispatch(setUploadStatus(UPLOAD_STATUS_MAP.PROCESSING));
    }
  };
}

/**
 * Confirms that we should upload processed file into our DB
 * @param {number} campaignid the campaign in question
 * @param {number} id The id of the processing results in question
 */
export function confirmUpload(campaignid, id) {
  return async (dispatch) =>
    dispatch(
      fetchRequest("CONFIRM_UPLOAD", "POST", "/confirmUpload", {
        campaignid,
        id,
      })
    ).then(() => {
      dispatch(setUploadStatus(UPLOAD_STATUS_MAP.IMPORTING_PROCESSED));
    });
}

/**
 * Action creator for set upload status actions
 * @param {string} status The new status for the upload page
 */
export function setUploadStatus(status, error=undefined) {
  return dispatch => dispatch({
    type: "SET_UPLOAD_STATUS",
    value: status,
    error
  });
}

/**
 * Cancels upload process and deletes processing results in db
 * @param {number} campaignid the campaign in question
 * @param {number} id The id of the processing results in question
 */
export function cancelUpload(campaignid, id) {
  return async (dispatch) =>
    dispatch(
      fetchRequest("CANCEL_UPLOAD", "POST", "/cancelUpload", {
        campaignid,
        id,
      })
    ).then(() => {
      dispatch(setProcessingResultsChanged(true));
    });
}

export function fetchUploadProcessingResults(campaignid) {
  return async (dispatch) =>
    dispatch(
      fetchRequest(
        "FETCH_PROCESSING_RESULTS",
        "POST",
        "/retrieveUploadProcessingResults",
        {
          campaignid,
        }
      )
    );
}

export function setProcessingResultsChanged(changed) {
  return (dispatch) => {
    dispatch({
      type: "SET_PROCESSING_RESULTS_CHANGED",
      value: changed,
    });
  };
}

