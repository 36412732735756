import React from "react";
import { Notification } from "../Notification";
import {addNotificationTypeToFactory} from "../NotificationFactoryV2";
const ErrorMessageNotification = ({ campaignid, error, ...props }) => {
  return (
    <Notification {...props} className="is-danger" fadeOutTimer={15}>
      Campaign <b>({campaignid})</b>: Error occurred while performing background
      task.
      {error}
    </Notification>
  );
};

export default ErrorMessageNotification;

addNotificationTypeToFactory("ErrorMessageNotification", ErrorMessageNotification);
